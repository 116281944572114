<template>
	<div class="article-wenzhang">
		<div class="" v-if="ArticleDetailInfo!=null">
			<!-- 详情视频 -->
			<div class="article-wena">
				<!-- 详情视频头部 -->
				<div class=""><a href="#/youji/index">首页</a>></div>
				<div class=""><a href="#/youji/list">游记</a>></div>
				<div class="">{{ArticleDetailInfo.Title}}</div>
			</div>
			<div class="article-wenb">
				<!-- 详情视频作者跟内容 -->
				<div class="article-wenc">
					<div class="article-wene">
						<div class="article-wenf">{{ArticleDetailInfo.Title}}</div>
						<div class="article-weng">
							<div class="article-weng">
								<div class="article-wenze">作者:</div>
								<a href="javascript:;"
									class="article-wenh article-wenhh">{{ArticleDetailInfo.AuthorName}}</a>
							</div>
							<div class="article-wenh">类型:&nbsp;{{ArticleDetailInfo.ArticlecategoryName}}</div>
							<div class="">|</div>
							<div class="article-wenh">浏览数:&nbsp;{{ArticleDetailInfo.ViewCount}}</div>
							<div class="">|</div>
							<div class="article-wenh">发表于:&nbsp;{{ArticleDetailInfo.CreateDate}}</div>
							<div class="">|</div>
							<div class="article-wenh">更新于:&nbsp;{{ArticleDetailInfo.LatestModifyDate}}</div>
						</div><br>
						<div class="article-weni">
							<!-- <div class="">#一起飞年会<br>一位入职17年的一起飞人<br>记录不平凡的一年<br>感恩一路有您的陪伴和支持🌹😊[爱心][福]</div>
							<div class="">
								#企业文化#幸福旅程#国际机票@一起飞国际机票网 @国际旅行私人管家
							</div><br> -->

							{{ArticleDetailInfo.Summarize}}
						</div>
						<!-- 视频模式 -->
						<div class="" v-if="ArticleDetailInfo.IsHasVideo">
							<video style="position:relative;z-index:1;background-color:#666;width: 100%; height: 400px;"
								height="" playsinline="" webkit-playsinline=""
								:poster="ArticleDetailInfo.CoverImagePath" :src="ArticleDetailInfo.VideoPath"
								controls="controls">
							</video>
						</div>

						<!--图片模式-->
						<div class="article-micro"
							v-else-if="ArticleDetailInfo.ArticleImages!=null&&ArticleDetailInfo.ArticleImages.length>0">
							<div class="article-mimg">
								<!-- 详情图片轮播图片 -->
								<div class="article-mimga">
									<div class="article-imga">
										<div class="article-imgb">
											<!-- 详情图片轮播图片 -->
											<div class="article-zuoa">
												<div class="article-zuo" @click="bindPicPre"><img
														src="../../assets/zuojiand.png"></div>
											</div>
											<div class="article-imgd">
												<div class="" v-for="(item,index) in ArticleDetailInfo.ArticleImages"
													:key="index">
													<el-image fit="scale-down" :src="item.Path" class="article-imge"
														v-if="imgUrlIndex==index" :preview-src-list="urls"></el-image>
												</div>
											</div>
											<div class="article-zuoa">
												<div class="article-you" @click="bindPicNext"><img
														src="../../assets/youjiand.png"></div>
											</div>
										</div>

										<div class="article-iamg">
											<!-- 详情图片轮播下方图片滑块 -->
											<div class="article-youhuc">
												<div class="article-zuohua" :style="callefta" @click="zuohua"><img
														src="../../assets/zuojiantouxm.png"></div>
											</div>
											<div class="">
												<div class="article-iamhh">
													<div class="article-iamh" :style="{ left: calleft + 'px' }">
														<div class=""
															v-for="(item,index) in ArticleDetailInfo.ArticleImages"
															:key="index">
															<div class="article-iamp">
																<img :src="item.Path"
																	:class="[imgUrlIndex==index?'article-iamk':'article-iamo']"
																	@click="wan(index)" class="article-iamj">
																<div class=""
																	:class="[imgUrlIndex==index?'article-iamoo':'article-iamooo']">
																</div>
															</div>
														</div>
													</div>
												</div>
											</div>
											<div class="article-youhuc">
												<div class="article-youhua" :style="calleftb" @click="youhua"><img
														src="../../assets/youjianx.png"></div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div class="article-micro articleContent" v-else>
							<div v-html="ArticleDetailInfo.Content"></div>
						</div>

						<div class="article-fengexian">
							<!-- 详情图片轮播下方分割线 -->
							<el-divider>
								<div class="article-fengexiana">The End</div>
							</el-divider>
						</div>

						<!-- 详情图片轮播下方点赞跟头像 -->
						<div class="article-dianzan" v-if="ArticleVoteList!=null">
							<div class="article-dianzana">
								<a href="javascript:;">
									<img src="../../assets/dianz.png">
								</a>
							</div>
							<block v-for="(item,index) in ArticleVoteList" :key="index">
								<div class="article-dianzand">
									<a href="javascript:;">
										<el-avatar :size="40" :src="item.VoterFacePath">
										</el-avatar>
									</a>
								</div>
							</block>
							<div class="article-dianzane">
								<a href="javascript:;">
									{{ArticleVoteCount}}
								</a>
							</div>
						</div>

						<div class="article-wenz">
							<!-- 详情视频下方点赞收藏 -->
							<div class="article-wenm" @click="bindDianZang()">
								<div class="article-wenn">
									<img src="../../assets/dianzanle.png" class="article-wenl">
									<!-- <img src="../../assets/dianzanf.png" class="article-wenl"> -->
								</div>
								<div class="article-weno">点赞</div>
							</div>
							<div class="article-wenm" @click="bindShouCang()">
								<div class="article-wenn">
									<img src="../../assets/shoucang.jpg" class="article-wenl">
									<!-- <img src="../../assets/shoucanga.png" class="article-wenl"> -->
								</div>
								<div class="article-weno">收藏</div>
							</div>
							<div class="article-wenm" @click="bindFenXiang()">
								<div class="article-wenn">
									<img src="../../assets/fenxiang.jpg" class="article-wenl">
								</div>
								<div class="article-weno">分享</div>
							</div>
							<div class="article-wenm" @click="bindPingLun()" v-show="false">
								<div class="article-wenn">
									<img src="../../assets/pingl.png" class="article-wenl">
								</div>
								<div class="article-weno">评论</div>
							</div>
						</div>
					</div>

					<div class="article-pinglun" v-show="false">
						<div class="article-pinl">
							<div class="article-pinla">
								<el-avatar :size="50"
									src="https://cube.elemecdn.com/0/88/03b0d39583f48206768a7534e55bcpng.png">
								</el-avatar>
							</div>
							<div class="article-pinlc">
								<el-input v-model="input" placeholder="喜欢这篇游记吗？说点什么吧..." class="article-pinlb">
								</el-input>
								<div class="article-pinle"><img src="../../assets/biaoqing.png"></div>
							</div>
							<div class="article-pinld">
								<div class="article-wenw">发表评论</div>
							</div>
						</div>

						<div class="article-pinlf" v-show="false">
							<div class="article-pinlq" v-for="item in [1,2,3]" :key="item">
								<div class="article-pinlg">
									<div class="article-pinla">
										<el-avatar :size="50"
											src="https://cube.elemecdn.com/0/88/03b0d39583f48206768a7534e55bcpng.png">
										</el-avatar>
									</div>
									<div class="article-pinlh">王楚钦</div>
								</div>
								<div class="article-pinli">
									<div class="">
										一起飞一起飞一起飞一起飞一起飞一起飞一起飞一起飞一起飞一起飞一起飞
										一起飞一起飞一起飞一起飞一起飞一起飞一起飞一起飞一起飞一起
										一起飞一起飞一起飞一起飞一起飞一起飞一起飞一起飞
										一起飞一起飞一起飞一起飞一起飞
									</div>
									<div class="article-pinlj">
										<div class="article-pinlk">2022-03-25</div>
										<div class="article-pinll">
											<div class="article-pinlp">
												<img src="../../assets/dianzan.png" class="">
											</div>
											<div class="">2022</div>
										</div>
										<div class="article-pinlm">回复</div>
									</div>
								</div>
							</div>

							<div class="article-pinli">
								<div class="article-pinlgg">
									<div class="" v-for="item in [1,2,3]" :key="item">
										<div class="article-pinlg">
											<div class="article-pinla">
												<el-avatar :size="30"
													src="https://cube.elemecdn.com/0/88/03b0d39583f48206768a7534e55bcpng.png">
												</el-avatar>
											</div>
											<div class="article-pinlh">老扁</div>
											<div class="article-pinlh">回复</div>
											<div class="article-pinlh">王楚钦</div>
										</div>
										<div class="article-pinlhf">
											<div class="">
												一起飞一起飞一起飞一起飞一起飞一起飞一起飞一起飞一起飞一起飞一起飞
												一起飞一起飞一起飞一起飞一起飞一起飞一起飞一起飞一起飞一起
												一起飞一起飞一起飞一起飞一起飞一起飞一起飞一起飞
												一起飞一起飞一起飞一起飞一起飞
											</div>
										</div>
										<div class="article-pinljj">
											<div class="article-pinlk">2022-03-25</div>
											<div class="article-pinll">
												<div class="article-pinlp">
													<img src="../../assets/dianzan.png" class="">
												</div>
												<div class="">2022</div>
											</div>
											<div class="article-pinlm">回复</div>
										</div>
									</div>

									<div class="article-huifuu">
										<div class="article-huifu">
											<div class="">共3214条回复，</div>
											<div class="">点击查看</div>
										</div>
									</div>
								</div>
							</div>
						</div>

						<div class="article-pinglunzz">
							<div class="article-pinglunz">
								<div class="">共评论9857条，</div>
								<div class="">点击查看</div>
							</div>
						</div>
					</div>

					<div class="article-wenp" v-show="false">
						<!-- 详情视频下方评论框 -->
						<div class="article-wenq">参与评论</div>
						<div class="article-wenr">
							<el-input type="textarea" placeholder="喜欢这篇游记吗？说点什么吧..." v-model="textarea" maxlength="100"
								resize="none" show-word-limit class="article-wens"> </el-input>
						</div>
						<div class="article-went">
							<div class="article-wenu"><img src="../../assets/biaoqing.png"></div>
							<div class="article-wenw">参与评论</div>
						</div>
					</div>
				</div>
				
				<div class="article-wend">
					<!-- 详情视频右边顾问用户 -->
					<div class="article-wenj" v-if="EmployeeInfo!=null">
						<div class="article-weny">
							<a href="javascript:;">
								<el-avatar :size="80" :src="EmployeeInfo.UserHeadImg" class="article-img"></el-avatar>
							</a>
							<div class="article-hwl">
								<a href="javascript:;" @click="bindShowIM(EmployeeInfo.Account)">
									<img src="../../assets/hewoliao.gif" class="article-hwla">
								</a>
							</div>
						</div>
						<div class="article-name">
							<a href="" class="article-namea">{{EmployeeInfo.Name}}</a>
							<div class="article-nameb" v-if="EmployeeInfo.StaffContinents!=null">
								<div class="article-namec">精通领域:</div>
								<div class="article-named" :title="EmployeeInfo.StaffContinents">
									{{EmployeeInfo.StaffContinents}}
								</div>
							</div>
							<div class="article-namee article-namef" v-show="false">
								<a href="javascript:;" class="article-namee article-nameg">
									<span href="" class="article-nameh">游记</span>
									<span class="article-namei">{{EmployeeInfo.YouJiCount}}</span>
								</a>
								<!-- <div class="article-nameg article-namej">|</div>
								<div class="article-namee article-nameg">
									<div class="">贡献值</div>
									<div class="article-namei">192940</div>
								</div> -->
							</div>
							<a v-show="false" href="javascript:;" class="article-namee article-namef article-namek"
								@click="bindGuanZhu()">
								<img src="../../assets/jiahao.png" class="article-guan">
								<div class="">加关注</div>
							</a>

							<!-- <div class="article-guanz">
								<div class="article-guanza">
									<a href="" class="article-namee article-namef article-namek">
										<img src="../../assets/dagou.png" class="article-guan">
										<div class="">已关注</div>
									</a>
								</div>
								<div class="article-guanzb">
									<a href="" class="article-namee article-namef article-namek">
										<img src="../../assets/shanchub.png" class="article-guan">
										<div class="">取消关注</div>
									</a>
								</div>
							</div> -->
						</div>
						<div class="article-lvyou">
							<div class="article-lvyoua" v-if="EmployeeInfo.StaffIntro!=''">
								<div class="article-lvyoub">个人简介:</div>
								<div class="article-lvyouc">{{EmployeeInfo.StaffIntro}}</div>
							</div>
							<div class="article-lvyoua" v-if="EmployeeInfo.WorkPhone!=''">
								<div class="article-lvyoub">顾问电话:</div>
								<div class="article-lvyouc">{{EmployeeInfo.WorkPhone}}</div>
							</div>
							<div class="article-lvyoua" v-if="EmployeeInfo.Email!=''">
								<div class="article-lvyoub">顾问邮箱:</div>
								<div class="article-lvyouc">{{EmployeeInfo.Email}}</div>
							</div>
						</div>
					</div>
					
					<div class="article-namell" v-show="false">
						<!-- 详情视频右边浏览 -->
						<div class="article-namel">
							<div class="article-namem">最近来访</div>
							<div class="article-namen">累计浏览</div>
							<div class="article-nameo">7144</div>
						</div>
						<div class="">
							<el-avatar :size="40"
								src="https://cube.elemecdn.com/0/88/03b0d39583f48206768a7534e55bcpng.png"></el-avatar>
						</div>
					</div>

					<div class="article-namep" v-show="false">
						<!-- 详情视频右边二维码 -->
						<div class="article-nameq">
							<img src="http://t.yqf.cn/qr/kQlyWG?level=M&backcolor=%23fff&forecolor=%23000&blockpixel=8&marginblock=1&logo=1"
								class="article-namer">
						</div>
						<div class="article-names">
							<div class="article-namet">当前文章二维码</div>
							<div class="article-nameu">随手扫一扫二维码，轻松把当前文章分享给好友，或转发到朋友圈</div>
						</div>
					</div>

					<div class="article-mimgyj" v-if="ArticleList!=null">
						<!-- 详情图片右边游记  点击后跳转详情视频-->
						<div class="article-mimgyja">相关游记</div>
						<div class="article-mimgyjz">
							<div class="article-mimgyjb" v-for="(item,index) in ArticleList" :key="index">
								<div class="article-mimgyjc">
									<a href="javascript:;" @click="bindJumpArticleDetail(item)">
										<el-image fit="cover" :src="item.CoverImagePath" class="article-mimgyjd"></el-image>
									</a>
								</div>
								<div class="article-mimgyjee">
									<div class="article-mimgyje">
										<a href="javascript:;" @click="bindJumpArticleDetail(item)">{{item.Title}}</a>
									</div>
									<div class="article-mimgyjf">
										<a href="javascript:;">{{item.AuthorName}}</a>
										<a href="">
											<img src="../../assets/liulan.png" class="article-mimgyjfg">
											<p class="">{{item.ViewCount}}</p>
										</a>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>

		<!-- <div class="" v-show="false">			
			<div class="article-ding" :class="arti">
				<div class="article-dinga">
					<div class="article-dingc">
						<div class="article-dingd">							
							<el-avatar :size="40"
								src="https://cube.elemecdn.com/0/88/03b0d39583f48206768a7534e55bcpng.png"></el-avatar>
						</div>
						<div class="article-dinge">
							<div class="article-dingf">【周边游必囤】 增城森林海2周年活动🔥¥1188抢森林海山景房/别墅拆分房（二选一）还有门票特惠</div>
							<div class="article-dingg">								
								<div class="">作者:</div>
								<div class="article-dingh">王楚钦</div>
							</div>
						</div>
					</div>
					<div class="article-dingi">						
						<div class="article-dingj">
							<a href="">
								<img src="../../assets/dianz.png" class="article-dingk">
								<p class="article-microasc">喜欢0</p>
							</a>
						</div>
						<div class="article-dingj">
							<a href="">
								<img src="../../assets/shoucang.jpg" class="article-dingk">
								<p class="article-microafx">收藏0</p>
							</a>
						</div>
						<div class="article-dingj">
							<a href="">
								<img src="../../assets/fenxiang.jpg" class="article-dingk">
								<p class="article-microaxh">分享1</p>
							</a>
						</div>
						<div class="article-dingj">
							<a href="">
								<img src="../../assets/pingl.png" class="article-dingk">
								<p class="article-microapl">评论0</p>
							</a>
						</div>
						<div class="article-dingj article-erwei">
							<a href="">
								<img src="../../assets/erweimaz.jpg" class="article-dingk">
								<p class="article-microapl">文章二维码</p>
							</a>
							<div class="article-erweimm">
								<div class="article-erweim">
									<div class="article-erweima"></div>
									<div class="article-erweimb"></div>
									<div class="article-erw">
										<img src="http://t.yqf.cn/qr/bQxHJh?level=M&backcolor=%23fff&forecolor=%23000&blockpixel=8&marginblock=1&logo=1"
											class="article-erwe">
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div class="article-wena">				
				<div class=""><a href="">首页</a>></div>
				<div class=""><a href="">微游记</a>></div>
				<div class="">【周边游必囤】 增城森林海2周年活动🔥¥1188抢森林海山景房/别墅拆分房（二选一）还有门票特惠</div>
			</div>

			<div class="article-micro">
				<div class="article-microa">					
					<el-avatar :size="60" src="https://cube.elemecdn.com/0/88/03b0d39583f48206768a7534e55bcpng.png">
					</el-avatar>
					<div class="article-microb">
						<div class="article-microc">
							<div class="">【周边游必囤】 增城森林海2周年活动🔥¥1188抢森林海山景房/别墅拆分房（二选一）还有门票特惠<span
									class="article-microd">[12图]</span></div>
						</div>
						<div class="article-microe">
							<div class="article-microf">
								<div class="article-microg">
									<div class="article-microh">作者:</div>
									<a href="" class="article-microi">
										<p>王楚钦</p>
									</a>
								</div>
								<div class="">
									<a href="" class="article-microg article-microj">
										<p><img src="../../assets/guanzhu.png" class="article-gz"></p>
										<p>关注</p>
									</a>									
								</div>
								<div class="article-microg">
									<div class="article-microk">发表于:</div>
									<div class="">2022-03-05</div>
								</div>
								<div class="article-microl">|</div>
								<div class="article-microg">
									<div class="article-microk">更新于:</div>
									<div class="">2022-03-05</div>
								</div>
								<div class="article-microl">|</div>
								<div class="article-microg">
									<div class="article-microk">浏览数:</div>
									<div class="">729</div>
								</div>
							</div>
							<div class="article-microf">								
								<div class="article-microab">
									<a href="">
										<img src="../../assets/shoucang.jpg" class="article-microam">
										<p class="article-microasc">收藏</p>
									</a>
								</div>
								<div class="article-microab">
									<a href="">
										<img src="../../assets/fenxiang.jpg" class="article-microam">
										<p class="article-microafx">分享</p>
									</a>
								</div>
								<div class="article-microab">
									<a href="">
										<img src="../../assets/dianz.png" class="article-microam">
										<p class="article-microaxh">喜欢1</p>
									</a>
								</div>
								<div class="article-microab">
									<a href="">
										<img src="../../assets/pingl.png" class="article-microam">
										<p class="article-microapl">评论0</p>
									</a>
								</div>
							</div>
						</div>
					</div>
				</div>

				<div class="article-mimg">					
					<div class="article-mimga">
						<div class="article-mimgb">【周边游必囤】 增城森林海2周年活动🔥¥1188抢森林海山景房/别墅拆分房（二选一）还有门票特惠</div>
						<div class="article-imga">
							<div class="article-imgb">								
								<div class="article-zuoa">
									<div class="article-zuo" @click="jian"><img src="../../assets/zuojiand.png"></div>
								</div>
								<div class="article-imgd">
									<div class="" v-for="(item,index) in urls" :key="index">
										<img :src="item" class="article-imge" v-if="imgUrlIndex==index">
									</div>
								</div>
								<div class="article-zuoa">
									<div class="article-you" @click="jia"><img src="../../assets/youjiand.png"></div>
								</div>
							</div>


							<div class="article-iamg">								
								<div class="article-youhuc">
									<div class="article-zuohua" :style="callefta" @click="zuohua"><img
											src="../../assets/zuojiantouxm.png"></div>
								</div>
								<div class="">
									<div class="article-iamhh">
										<div class="article-iamh" :style="{ left: calleft + 'px' }">
											<div class="" v-for="(item,index) in urls" :key="index">
												<div class="article-iamp">
													<img :src="item"
														:class="[imgUrlIndex==index?'article-iamk':'article-iamo']"
														@click="wan(index)" class="article-iamj">
													<div class=""
														:class="[imgUrlIndex==index?'article-iamoo':'article-iamooo']"></div>
												</div>
											</div>
										</div>
									</div>
								</div>
								<div class="article-youhuc">
									<div class="article-youhua" :style="calleftb" @click="youhua"><img
											src="../../assets/youjianx.png"></div>
								</div>
							</div>
						</div>
						<div class="article-fengexian">							
							<el-divider>
								<div class="article-fengexiana">The End</div>
							</el-divider>
						</div>

						<div class="article-dianzan">							
							<div class="article-dianzana">
								<a href="">
									<img src="../../assets/dianz.png">
								</a>
							</div>
							<div class="article-dianzand">
								<a href="">
									<el-avatar :size="40"
										src="https://cube.elemecdn.com/0/88/03b0d39583f48206768a7534e55bcpng.png">
									</el-avatar>
								</a>
							</div>
							<div class="article-dianzane">
								<a href="">
									1
								</a>
							</div>
						</div>

						<div class="article-pl">							
							<div class="article-wenq">参与评论</div>
							<div class="article-wenr">
								<el-input type="textarea" placeholder="喜欢这篇游记吗？说点什么吧..." v-model="textarea"
									maxlength="100" resize="none" show-word-limit class="article-wens"> </el-input>
							</div>
							<div class="article-went">
								<div class="article-wenu"><img src="../../assets/biaoqing.png"></div>
								<div class="article-wenw">参与评论</div>
							</div>
						</div>
					</div>


					<div class="article-mimgyj">						
						<div class="article-mimgyja">相关游记</div>
						<div class="article-mimgyjb">
							<div class="article-mimgyjc">
								<a href="">
									<img src="http://img8.yiqifei.com/20220123/369d88e313934c3f83b500aeb93beef0.png!230x170"
										class="article-mimgyjd">
								</a>
							</div>
							<div class="article-mimgyje">
								<a href="">#一起飞年会 一位入职17年的一起飞人记录不平凡的一年感恩一路有您的陪伴和支持🌹😊</a>
							</div>
							<div class="article-mimgyjf">
								<a href="">王楚钦</a>
								<a href="">
									<img src="../../assets/dianzan.png" class="">
									<p class="">0</p>
								</a>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div> -->
	</div>
</template>

<script>
	var util = require('@/utils/util.js');
	var yqfCommon = require('@/utils/yqfCommon.js');
	var datehelper = require('@/utils/datehelper.js');
	var staticData = require('@/data/staticData.js');
	//接口	
	var wqgApi = require('@/api/wqgApi.js');
	var yqfApi = require('@/api/yqfApi.js');
	//组件引用

	//公共属性
	var that;

	export default {
		components: {
			//组件

		},
		data() {
			return {
				id: '',
				title: "社区首页",
				input: '',
				textarea: '',
				calleft: 0,
				callefta: 'display: none;',
				calleftb: 'display: block;',
				imgUrlIndex: 0,
				urls: [
					'https://fuss10.elemecdn.com/a/3f/3302e58f9a181d2509f3dc0fa68b0jpeg.jpeg',
					'https://fuss10.elemecdn.com/1/34/19aa98b1fcb2781c4fba33d850549jpeg.jpeg',
					'https://fuss10.elemecdn.com/0/6f/e35ff375812e6b0020b6b4e8f9583jpeg.jpeg',
					'https://fuss10.elemecdn.com/9/bb/e27858e973f5d7d3904835f46abbdjpeg.jpeg',
					'https://fuss10.elemecdn.com/d/e6/c4d93a3805b3ce3f323f7974e6f78jpeg.jpeg',
					'https://fuss10.elemecdn.com/3/28/bbf893f792f03a54408b3b7a7ebf0jpeg.jpeg',
					'https://fuss10.elemecdn.com/2/11/6535bcfb26e4c79b48ddde44f4b6fjpeg.jpeg',
					'https://fuss10.elemecdn.com/2/11/6535bcfb26e4c79b48ddde44f4b6fjpeg.jpeg',
					'https://fuss10.elemecdn.com/2/11/6535bcfb26e4c79b48ddde44f4b6fjpeg.jpeg',
					'https://fuss10.elemecdn.com/2/11/6535bcfb26e4c79b48ddde44f4b6fjpeg.jpeg',
					'https://fuss10.elemecdn.com/2/11/6535bcfb26e4c79b48ddde44f4b6fjpeg.jpeg',
					'https://fuss10.elemecdn.com/2/11/6535bcfb26e4c79b48ddde44f4b6fjpeg.jpeg',
					'https://fuss10.elemecdn.com/2/11/6535bcfb26e4c79b48ddde44f4b6fjpeg.jpeg',
					'https://fuss10.elemecdn.com/1/34/19aa98b1fcb2781c4fba33d850549jpeg.jpeg'
				],
				arti: '',
				ArticleID: 'f8ce1dbef7ab4d2d9e864507eb2dac11',
				ArticleDetailInfo: null, //文章详情
				EmployeeInfo: null, //旅行顾问详情
				ArticleList: null, //相关游记
				ArticleVoteList: null, //点赞列表
				ArticleVoteCount: 0
			};
		},
		created() {
			that = this;
			var options = that.$route.query;
			console.log(options);
			if (!yqfCommon.isNullOrEmpty(options.id)) {
				that.ArticleID = options.id;
			}
			GetArticleDetailByID();
			$(window).scrollTop("0");
		},
		methods: {
			bindJumpArticleDetail: function(e) {
				let path = '/Youji/article?id=' + e.ID;
				console.log(path);
				// window.location.href=path;
				that.$router.push({
					path: path
				});
				that.ArticleID = e.ID;
				GetArticleDetailByID();
				$(window).scrollTop("0");
			},
			zuohua() {
				// 详情图片轮播下方图片滑块事件
				this.calleft += +747;
				if (this.calleft >= +0) {
					this.callefta = 'display: none;';
				}
				if (this.calleft >= -747) {
					this.calleftb = 'display: block;';
				}
			},
			youhua() {
				// 详情图片轮播下方图片滑块左右事件
				this.calleft += -747;
				if (this.calleft <= -1494) {
					this.calleftb = 'display: none;';
				}
				if (this.calleft <= -747) {
					this.callefta = 'display: block;';
				}
			},
			wan(index) {
				// 详情图片轮播下方图片滑块左右事件
				this.imgUrlIndex = index
				console.log(index)
			},
			bindPicPre() {
				// 情图片轮播图片左右事件

				if (that.ArticleDetailInfo.ArticleImages != null) {
					if (that.imgUrlIndex > 0) {
						that.imgUrlIndex--
					}
				}
				console.log(that.imgUrlIndex)
			},
			bindPicNext() {
				// 情图片轮播图片左右事件
				if (that.ArticleDetailInfo.ArticleImages != null) {
					let imgUrlIndex = that.imgUrlIndex;
					imgUrlIndex++;
					if (imgUrlIndex >= that.ArticleDetailInfo.ArticleImages.length) {
						return;
					}
					that.imgUrlIndex = imgUrlIndex;
				}

				console.log(that.imgUrlIndex)
			},
			//点赞
			bindDianZang: function(e) {
				var parm = {
					articleid: that.ArticleID
				};
				wqgApi.ArticleVoteAdd(parm, function(result) {
					console.log(result);
					if (result.code == 0) {
						GetArticleVoteList();
					} else {
						that.$alert(result.msg);
					}
				});
			},
			//收藏
			bindShouCang: function(e) {

			},
			//分享
			bindFenXiang: function(e) {

			},
			//关注
			bindGuanZhu: function(e) {

			},
			//评论
			bindPingLun: function(e) {

			},
			bindShowIM:function(e){
				if (!util.checkLogin()) {
					that.$alert('请先登录');
					// wx.showModal({
					// 	title: '提示',
					// 	content: '请登录后操作',
					// 	success(res) {
					// 		if (res.confirm) {
					// 			wx.navigateTo({
					// 				url: '/pages/accounts/login/login'
					// 			});
					// 		}
					// 	}
					// });
					return;
				}
				let tousercode = e;
				// let Accountinfo = getAccountInfo();
				let parm = {
					UserCode: util.getAccountCode(),
					tousercode: tousercode
				};
				// uni.showLoading({
				// 	title: '加载中'
				// });
				yqfApi.GetIMUrl(parm, function(result) {
					console.log(result);
					// uni.hideLoading();
					if (result.code == 0) {
						let imurl = result.data.imurl;
						// let url = '/pages/imCenter/imview/imview?imurl=' + imurl + '';
						// util.navigateTo(url);
						window.open(imurl);
					} else {
						that.$alert('请先登录');
					}
				});
			}
		}
	};
	window.onscroll = function() {
		// 头部定位下拉后出现
		//scrollTop是浏览器滚动条的top位置，
		var scrollTop = document.body.scrollTop || document.documentElement.scrollTop;
		//通过判断滚动条的top位置与可视网页之和与整个网页的高度来返回各项参数
		// console.log(scrollTop)
		if (scrollTop >= 1) {
			that.arti = 'article-dingb'
		} else {
			that.arti = ''
		}
	}
	//获取文章详情
	function GetArticleDetailByID() {
		that.imgUrlIndex = 0;
		var parm = {
			id: that.ArticleID
		};
		wqgApi.GetArticleDetailByID(parm, function(result) {
			console.log(result);
			if (result.code == 0) {
				that.ArticleDetailInfo = result.data.ArticleDetailInfo;
				if (that.ArticleDetailInfo.ArticleImages != null && that.ArticleDetailInfo.ArticleImages.length >
					0) {
					let imgUrls = [];
					for (var i = 0; i < that.ArticleDetailInfo.ArticleImages.length; i++) {
						imgUrls.push(that.ArticleDetailInfo.ArticleImages[i].Path);
					}
					that.urls = imgUrls;
				}
				GetEmployeeInfo();
				GetArticleListByUserCode();
				GetArticleVoteList();
			} else {
				// that.$router.push({
				// 	path: '/Flight'
				// });
			}
		});
	}
	//文章作者详情
	function GetEmployeeInfo() {
		var parm = {
			EmployeeUserCode: that.ArticleDetailInfo.UserCode
		};
		wqgApi.GetEmployeeInfo(parm, function(result) {
			console.log(result);
			if (result.code == 0) {
				that.EmployeeInfo = result.data.EmployeeInfo;
			} else {
				// that.$router.push({
				// 	path: '/Flight'
				// });
			}
		});
	}
	//获取相关的游记
	function GetArticleListByUserCode() {
		var parm = {
			PageSize: 4, //总页数
			OrderBy: 2, //排序方式(1、创建时间 2、浏览量 3、评论数 4、点赞数 99、无序 默认为按创建时间)
			PageIndex: 1,
			UserCode: that.ArticleDetailInfo.UserCode,
			articleid: that.ArticleID
		};
		wqgApi.GetArticleListByUserCode(parm, function(result) {
			console.log(result);
			if (result.code == 0) {
				that.ArticleList = result.data.ArticleList;
			} else {

			}
		});
	}
	//获取点赞列表
	function GetArticleVoteList() {
		var parm = {
			PageSize: 10, //总页数			
			PageIndex: 1,
			articleid: that.ArticleID
		};
		wqgApi.GetArticleVoteList(parm, function(result) {
			console.log(result);
			if (result.code == 0) {
				that.ArticleVoteList = result.data.ArticleVoteList;
				that.ArticleVoteCount = result.data.RowCount;
			} else {

			}
		});
	}
</script>

<style lang="scss">
	@import '../../style/youji/article.scss';
</style>
